<template>
    <SmartForm base_url="/document/type" base_path="/document/type" form_type="delete"></SmartForm>
</template>

<script>
import SmartForm from "@/view/components/SmartForm.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    components: {
        SmartForm,
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Documenti", route: "/document" },
            { title: "Tipi", route: "/document/type/index" },
            { title: "Elimina Tipo" }
        ]);
    }
};
</script>
